<template>
  <v-row justify="center" align="center">
    <v-col>
      <v-card
        class="mx-auto"
        color="grey lighten-5"
        width="800px"
      > 
        <v-flex class="py-2"></v-flex>
        <v-img class="mt-3 pt-4" height="100px" contain src="img/trans_logo.png" alt="City of Santa Clarita"></v-img>
        <!-- <v-card-title class=" headline">
        </v-card-title> -->
        <v-alert v-model="alert" type="info" dismissible>Slow down, it seems you are searching too many times. Wait 30 seconds</v-alert>
        <v-card-title  class="text-center" style="display:block;">
          Find details about your residency and zone.
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="model"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            persistent-placeholder
            label="Residency Look-Up"
            placeholder="Enter Your Address"
            item-text="BLDG_ADRES"
            hide-no-data
            hide-selected
            return-object
            clearable
            persistent-hint
            hint="Disclaimer: Every reasonable effort has been made to ensure the accuracy of the presented information. However, the City of Santa Clarita assumes no liability for any errors or omissions."
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <div>No Data Available</div>
              </template>
              <template v-else>
                {{ data.item.BLDG_ADRES }}
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-divider class="mb-0 pb-0"></v-divider>
        <v-expand-transition>
          <v-container class="grey lighten-5 py-2 pt-2 pb-0" v-show="ready">
            <GmapMap v-show="ready" :center="center" :options="mapOptions" :zoom="15" style="height:225px; width: 100%;" ref="map">
              <gmap-marker :position="markerPosition">
              </gmap-marker>
            </GmapMap>
            <!-- <v-progress-circular
              v-else-if="model"
              indeterminate
              color="primary"
            ></v-progress-circular> -->
            <v-row justify-center>
              <v-col :cols="12">
                <v-select
                  :items="sameFields"
                  item-text="label"
                  item-value="key"
                  label="Filter Fields"
                  v-model="tmp"
                  clearable
                  multiple
                >
                  <template
                    slot="selection"
                    slot-scope="{ item, index }"
                  >
                    <v-chip v-if="index <= 6">
                      <span>{{ mapKeys(item.key) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 7"
                      class="grey--text caption"
                    >(+{{ tmp.length - 7 }} others)</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-layout row wrap justify-start>
              <v-flex v-for="(field, i) in fields" :key="i" xs12 sm6>
                <v-textarea :prepend-inner-icon="icon(field.key)" :rows="getRows(field, i, fields)" class="pa-1" hide-details filled readonly :label="mapKeys(field.key)" :value="field.value">
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-expand-transition>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!model"
            color="primary"
            @click="model = null"
          >
            Clear
            <v-icon right>mdi mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import proj4 from 'proj4';

  export default {
    data: () => ({
      descriptionLimit: 60,
      oldEntry: null,
      timer: null,
      entries: [],
      double: false,
      isLoading: false,
      model: null,
      search: '',
      filterField: '',
      alert: false,
      loading: false,
      center: { lat: 34.391664, lng: -118.542586 },
      markerPosition: { lat: 34.391664, lng: -118.542586 },
      mapOptions: {},
      mappedKeys: {
        'attributes': 'Attributes',
        'geometry': 'Geometry',
        'BLDG_ADRES': 'Street Address',
        'JURIS': 'Jurisdiction',
        'AIN': 'Parcel Number',
        'TRACTNUM': 'Tract Number',
        'XSTREETMAJ': 'Major Intersection',
        'XSTREETMIN': 'Minor Intersection',
        'CouncilDis': 'Council District',
        'OAKTREE': 'Oak Trees on Property?',
        'SMD': 'Streetlight Maint District',
        'WATERPURV': 'Water Purveyor',
        'ANNEX': 'Annexation Date',
        'TRASHDAY': 'Trash Collection Day',
        'SWEEPDAY': 'Street Sweeping',
        'LAMD': 'LMD Zone',
        'FIREZONE': 'Within Firezone?',
        'ZONING': 'Zoning',
        'UDC_CODE': 'Zoning (Pre-July 2013)',
        'GENPLAN': 'General Plan Code',
        'YEARBUILT': 'Year Built (per Assessor)',
        'DFIRM': 'Floodzone',
        'POOL_SPA': 'Pool or Spa',
        'RD_ZONE': "Sheriff's Reporting District",
        'ACRES': 'Acreage Size (GIS)',
      },
      icons: {
        'Street Address': 'home',
        'Jurisdiction': 'location_city',
        'Trash Collection Day': 'delete',
        'Street Sweeping': 'delete_sweep',
        'Pool or Spa': 'pool',
        'Oak Trees on Property?': 'nature',
        "Sheriff's Reporting District": 'security',
        'Water Purveyor': 'waves',
        'Within Firezone?': 'whatshot',
      },
      tmp: [],
    }),

    computed: {
      ready() {
        return this.model && !this.loading;
      },
      sameFields() {
        if (!this.model) return [];

        // Filter fields according to mappedKeys and keep them in the defined order
        return Object.keys(this.mappedKeys)
          .filter(key => this.model[key] !== undefined && key !== 'attributes' && key !== 'geometry')  // Only include keys that exist in the model
          .map(key => ({
            label: this.mapKeys(key),
            key: key,
            value: !this.model[key].isEmpty && this.model[key] != ' '  ? this.model[key] : 'N/A'
          }));
      },
      fields() {
        if (!this.model) return [];

        // Retrieve and filter fields according to `tmp` selections, if any, and in the mappedKeys order
        let items = Object.keys(this.mappedKeys)
          .filter(key => this.model[key] !== undefined && key !== 'attributes' && key !== 'geometry')  // Exclude 'attributes' and 'geometry'
          .map(key => ({
            key: key,
            label: this.mapKeys(key),
            value: !this.model[key].isEmpty && this.model[key] != ' '  ? this.model[key] : 'N/A'
          }));

        if (this.tmp.length) {
          items = items.filter(item => this.tmp.includes(item.key));
        }
        return items;
      },
      items() {
        return this.entries.map(entry => ({
          ...entry,
          ...entry.attributes
        }));
      }
    },


    watch: {
      search(val) {
        if (!val || val.length < 2) return;

        this.timer = setTimeout(() => {
          this.isLoading = true;
          const replacedString = this.search.replace(/#/g, '%23');
          const query = `https://maps.santa-clarita.com/arcgis/rest/services/Property/MapServer/2/query?where=BLDG_ADRES+LIKE+%27%25${replacedString}%25%27&outFields=*&returnGeometry=true&f=pjson`;

          fetch(query, { method: 'POST' })
            .then(response => response.json())
            .then(data => {
              this.entries = data.features;
              this.alert = false;
            })
            .catch(err => {
              if (err.response && err.response.status === 429) {
                this.alert = true;
              }
            })
            .finally(() => (this.isLoading = false));
        }, 500);
      },
      model(val) {
        if (val) {
          this.loading = true;
          // Define the Proj4 projections
          const statePlaneCalV = '+proj=lcc +lat_1=34.03333333333333 +lat_2=35.46666666666667 +lat_0=33.5 +lon_0=-118 +x_0=2000000.0 +y_0=500000.0 +ellps=GRS80 +datum=NAD83 +units=us-ft +no_defs';
          const wgs84 = '+proj=longlat +datum=WGS84 +no_defs';

          // Initialize proj4 with the coordinate systems
          proj4.defs('EPSG:2229', statePlaneCalV);
          proj4.defs('EPSG:4326', wgs84);

          // Convert coordinates using Proj4
          const [lng, lat] = proj4('EPSG:2229', 'EPSG:4326', [val.geometry.x, val.geometry.y]);

          // Update marker position
          this.markerPosition = {
            lat: lat, // Latitude
            lng: lng, // Longitude
          };

          console.log(this.markerPosition)
          this.$refs.map.$mapPromise.then((map) => {
            map.panTo({lat: this.markerPosition.lat, lng: this.markerPosition.lng})
          })
          this.loading = false;
        }
      }
    },

    methods: {
      icon(field) {
        return '';
      },
      getRows(field) {
        return field.value && field.value.length > 30 ? 2 : 1;
      },
      mapKeys(key) {
        return this.mappedKeys[key] || key;
      }
    }
  };
</script>
